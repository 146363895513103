:root {
  /* Background and Deep Space Colors */
  --deep-space: #020307;
  --stellar-black: #0b0f1a;

  /* Star and Nebula Colors */
  --nebula-blue: #2a3a6e;
  --star-yellow: #ffc107;
  --pulsar-pink: #d13a9c;
  --quasar-purple: #6f42c1;
  --supernova-orange: #fd7e14;
  --nova-red: #dc3545;
  --black-hole: #000000;

  /* Ship and Tech Colors */
  --ship-silver: #a9a9a9;
  --tech-blue: #007bff;
  --hull-red: #88141d;

  /* UI and Interface Colors */
  --ui-bg: #1c1e28;
  --ui-accent: #4caf50;
  --ui-text: #e0e0e0;
  /* Warning color */
  --warning: #ff9800;
  /* Danger color */
  --danger: #f44336;
  /* Success color */
  --success: #4caf50;
  /* Info color */
  --info: #2196f3;
  /* Gold accent for pro features */
  --pro: #ffd700;
}

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-pill: 0;

$table-color: var(--ui-text);
$table-bg: var(--ui-bg);

$card-bg: var(--ui-bg);
$card-color: var(--ui-text);
$card-cap-color: var(--ui-text);
$card-border-color: var(--ship-silver);
$card-border-width: 0;

$list-group-bg: var(--ui-bg);
$list-group-color: var(--ui-text);
$list-group-hover-bg: var(--stellar-black);
$list-group-action-hover-color: var(--ui-text);

@import "../../node_modules/bootstrap/scss/bootstrap";

html,
body,
#app {
  height: 100vh;
  width: 100vw;
  font-size: 14px;
  color-scheme: dark;
}

body {
  background-color: var(--stellar-black);
  color: var(--ui-text);

  --bs-link-color: var(--info);
  --bs-link-hover-color: var(--star-yellow);
  --bs-link-decoration: none;
}

a {
  color: var(--info);
  text-decoration: none;

  &:hover {
    color: var(--star-yellow);
  }

  .icon {
    &:hover {
      fill: var(--star-yellow);
    }
  }
}

.sidebar {
  background-color: var(--ui-bg);

  .nav-link {
    &:hover .icon {
      fill: var(--star-yellow);
    }
  }

  .nav-pills .nav-link.active {
    --bs-nav-pills-link-active-bg: var(--stellar-black);
    --bs-nav-pills-link-active-color: var(--star-yellow);

    .icon {
      fill: var(--star-yellow);
    }
  }
}

#main-sidebar {
  width: 64px;
  position: fixed;
  border-right: solid 2px var(--stellar-black);
}

#main-content {
  margin-left: 64px;
}

.icon {
  height: 32px;
  width: 32px;
  fill: var(--ui-text);

  &.sm {
    height: 16px;
    width: 16px;
  }

  &.md {
    height: 32px;
    width: 32px;
  }

  &.lg {
    height: 64px;
    width: 64px;
  }

  &.icon-warning {
    fill: var(--warning);
  }

  &.icon-danger {
    fill: var(--danger);
  }

  &.icon-success {
      fill: var(--success);
  }

  &.icon-info {
    fill: var(--info);
  }

  &.icon-pro {
    fill: var(--pro);
  }

  &.icon-purple {
    fill: var(--quasar-purple);
  }

  &.icon-pink {
    fill: var(--pulsar-pink);
  }
}

.tree {
  list-style: None;
  margin: 0;
  padding: 0;
  font-size: 0.85rem;

  li {
    .label {
      padding: 0.25rem 0 0 0;
      display: block;

      &:hover {
        background-color: var(--stellar-black);
      }
    }
  }

  .tree-leaf {
    padding: 0.25rem 1rem 0 1rem;
    display: block;
    font-style: italic;

    &:hover {
      background-color: var(--stellar-black);
    }
  }

  .tree-node,
  .tree-leaf {
    cursor: pointer;
  }

  .tree-leaf {
    color: var(--tech-blue);
  }

  .caret-expanded:before {
    content: "▼";
  }

  .caret-collapsed:before {
    content: "▶";
  }

  .caret-collapsed,
  .caret-expanded {
    cursor: pointer;
    display: inline-block;
    width: 1rem;
    text-align: center;
  }

  ul {
    list-style: None;
    margin: 0;
    padding: 0 0 0 0.5rem;
  }

  .collapsed {
    display: none;
  }
}

.table-container {
  overflow: auto;

  &.table-short {
    max-height: 400px;
  }
}

.table {
  tfoot,
  thead {
    --bs-table-bg: var(--nebula-blue);
    --bs-table-color: var(--star-yellow);

    tr {
      border-bottom: 1px solid var(--black-hole);
    }
    th {
      padding: 0.5rem;
      border-right: 1px solid var(--ui-bg);
    }
  }

  tbody {
    td {
      border-right: 1px solid var(--stellar-black);
    }
    tr {
      border-bottom: 1px solid var(--stellar-black);
    }
  }
}

.accordion {
  --bs-accordion-bg: var(--stellar-black);
  --bs-accordion-border-width: 0;

  .accordion-button {
    --bs-accordion-active-bg: #fff;
    --bs-accordion-active-color: var(--stellar-black);

    &.collapsed {
      background-color: var(--ship-silver);
      color: var(--stellar-black);
    }
  }
}

.accordion.slim {
  --bs-accordion-bg: transparent;
  --bs-accordion-color: var(--ui-text);
  --bs-accordion-btn-focus-box-shadow: none;

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

  .accordion-button {
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: var(--star-yellow);

    &.collapsed {
      background-color: transparent;
      color: var(--ui-text);
    }
  }
}

.nav-link {
  --bs-nav-link-color: var(--ui-text);
  --bs-nav-link-hover-color: var(--star-yellow);
  --bs-nav-tabs-link-hover-border-color: transparent;
}

.popover {
  --bs-popover-bg: var(--stellar-black);
  --bs-popover-color: var(--ui-text);
  --bs-popover-body-color: var(--ui-text);
  --bs-popover-border-color: var(--tech-blue);
  --bs-popover-header-bg: var(--stellar-black);
  --bs-popover-header-color: var(--ui-text);

  min-width: 250px;
}

.cosmic-popup {
  border: 2px solid var(--tech-blue);
  background-color: var(--stellar-black);
  color: var(--ui-text);
  padding: 8px;
  max-width: 300px;
  box-shadow: 0 0 2px var(--tech-blue);
  z-index: 999999;

  &-arrow {
    fill: var(--tech-blue);
  }

  &-content {
    text-wrap: auto;
  }
}

.high-sec {
  color: var(--ui-accent);
}

.low-sec {
  color: var(--star-yellow);
}

.null-sec {
  color: var(--hull-red);
}

.pi-cell {
  img {
    max-width: 64px;
    border-radius: 10px;
  }
}

.card-block {
  border: 2px solid var(--black-hole);

  .card-header {
    border-bottom: 1px solid var(--black-hole);
  }

  .card-footer {
    border-top: 1px solid var(--black-hole);
  }

  .list-group-item {
    --bs-list-group-border-color: var(--stellar-black);
  }
}

.breadcrumb {
  --bs-breadcrumb-item-active-color: var(--star-yellow);
  --bs-breadcrumb-divider-color: var(--ship-silver);
}

ol.breadcrumb,
ul.breadcrumb {
  background-color: var(--ui-bg);
  padding: 0.75rem 1rem;
}

.btn-link {
  text-decoration: var(--bs-link-decoration);
}

.modal {
  --bs-modal-bg: var(--stellar-black);
  --bs-modal-color: var(--ui-text);
  --bs-modal-header-bg: var(--stellar-black);
  --bs-modal-header-color: var(--ui-text);
  --bs-modal-footer-bg: var(--stellar-black);
  --bs-modal-footer-border-color: var(--stellar-black);
  --bs-modal-footer-color: var(--ui-text);
  --bs-modal-footer-divider-color: var(--stellar-black);
  --bs-modal-backdrop-bg: var(--stellar-black);
  --bs-modal-backdrop-opacity: 0.5;

  .modal-content {
    border: 2px solid var(--nebula-blue);
  }
}

.border-pro {
  --bs-border-color: var(--pro);
}

.text-pro {
  color: var(--pro);
  --bs-text-color: var(--pro);
}

.dark {
  input,
  select,
  textarea {
    background-color: var(--stellar-black);
    color: var(--ui-text);
    border-color: var(--ship-silver);
  }

  input:focus,
  select:focus,
  textarea:focus {
    background-color: var(--stellar-black);
    color: var(--ui-text);
    border-color: var(--tech-blue);
  }

  ::placeholder {
    color: var(--ship-silver);
    opacity: 1;
  }
}

.popup {
  --bs-list-group-border-color: var(--tech-blue);
  border: 1px solid var(--bs-list-group-border-color);
  background-color: var(--stellar-black);
  z-index: 1000;

  ul {
    --bs-list-group-border-color: var(--tech-blue);
    --bs-list-group-active-bg: var(--nebula-blue);
    --bs-list-group-active-color: var(--ui-text);
    --bs-list-group-active-border-color: var(--nebula-blue);

    li {
      border-top: 1px solid var(--bs-list-group-border-color);
      background-color: var(--stellar-black);
    }

    li:first-child {
      border-top: 0;
    }

    li:last-child {
      border-bottom: 0;
    }
  }
}

.feature {
  display: grid;
  grid-template-columns: max-content auto;

  dt {
    font-weight: bold;
    background-color: var(--ui-bg);
    padding-right: 18px;
  }

  dt,
  dd {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  &.with-border {
    border-bottom: 1px solid var(--tech-blue);
    padding-bottom: 0.6rem;
  }
}

.indeterminate-progress-bar {
    /* Color */
    background-color: #d1d5db;

    /* Rounded border */
    border-radius: 9999px;

    /* Size */
    height: 0.5rem;

    position: relative;
    overflow: hidden;
}

.indeterminate-progress-bar__progress {
    /* Color */
    background-color: #3b82f6;

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 50%;

    /* Move the bar infinitely */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }
    to {
        left: 100%;
    }
}

/**
  Mobile-friendly table modifications.
 */
@media screen and (max-width: 600px) {
  .table thead,
  .table tfoot {
    display: none;
  }

  .table td {
    display: flex;
    align-items: center;
  }

  .table td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table .text-end {
    text-align: left !important;
  }

  .table tbody tr {
    display: block;
    border-bottom: 8px solid var(--stellar-black);
  }

  .cl-container,
  .cl-content,
  .sidebar-page,
  .sidebar-inner {
    height: auto !important;
  }
}

code {
  background-color: var(--stellar-black);
  color: var(--pulsar-pink);
  padding: 0.25rem;
  border-radius: 0.25rem;
}